@green: #2D7A29;
@darker-green: #276923;

html, body {
  height: 100%;
}

.overlay-legend {
  img {
    margin: 0.5em;
  }
}

.sidebar {
  a {
    color: @green;
  }

  .btn.btn-primary {
    color: #fff;
    background-color: @green;
    border-color: @green;
  }

  .btn.btn-primary.focus,
  .btn.btn-primary:focus,
  .btn.btn-primary:hover,
  .btn.btn-primary.active,
  .btn.btn-primary:active {
    color: #fff;
    background-color: @darker-green;
    border-color: @darker-green;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
  .btn.btn-primary.active.focus,
  .btn.btn-primary.active:focus,
  .btn.btn-primary.active:hover,
  .btn.btn-primary:active.focus,
  .btn.btn-primary:active:focus,
  .btn.btn-primary:active:hover {
    color: #fff;
    background-color: @darker-green;
    border-color: @darker-green;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.sidebar-layers {
  padding: 2em 1em 2em 0.5em;
  
  .legends {
    margin: 1.2em 0em 1.2em 0em;
    table {
      margin-bottom: 1em;
      td {
        vertical-align: top;
      }
    }
  }
}

.sidebar-home {
  padding: 2em 1em 0em 0em;

  h2 {
    margin-bottom: 0.6em;
  }

  .start {
    margin: 1em 0 1em 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.legend-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.legend-iconography {
  vertical-align: text-top;
  height: 1.5rem; 
  width: 1.5rem;
}

.legend-iconography-row {
  height: 1.5rem;
}

.legend-iconography-ramp {
  width: 1.5rem;
}

.legend-iconography-label {
  height: 1rem;
}

.legend-iconography-label-ramp-first {
  vertical-align: text-top;
}

.legend-iconography-label-ramp-last {
  vertical-align: bottom;
}

h6 {
  font-weight: 600;
}

.opacitySliderContainer {
  width: 100%; /* Width of the outside container */
}

.opacitySlider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.opacitySlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: @green;
  cursor: pointer;
}

.opacitySlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: @green;
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .opacitySlider {
    width: 100%;
    height: 2.5rem;
    border-radius: 5px;
    box-sizing: content-box;
    background: transparent;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #d3d3d3;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #d3d3d3;
  }
  input[type=range]::-ms-fill-upper {
    background: #d3d3d3;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #d3d3d3;
  }  
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: @green;
    cursor: pointer;
  }
}

.grid-button:hover {
  cursor: pointer;
}